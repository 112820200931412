<div class="client">
    <div class="client-info">
        <h2 class="title">Clients</h2>
        <p class="info">(personnes ayant utilisé la machine sur les 30 derniers jours)</p>
    </div>
    <div class="client-filter" *ngIf="isAdmin()">
        <select (change)="filterMachine($event)" class="date-selection">
            <option [value]="0">
                Toutes les salles
            </option>
            <option *ngFor="let machine of machines" [value]="machine.id">
                {{ machine.label }}
            </option>
        </select>
    </div>
    <div class="client-total">
        <table>
            <thead>
                <tr>
                    <th>Non abonnés</th>
                    <th>Abonnés</th>
                    <th>Abonné eau uniquement</th>
                    <th>Employé</th>
                    <th>Manager</th>
                    <th>Autre</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ totalLevel0 }}</td>
                    <td>{{ totalSubscribe }}</td>
                    <td>{{ totalWater }}</td>
                    <td>{{ totalEmployee }}</td>
                    <td>{{ totalManager }}</td>
                    <td>{{ totalOther }}</td>
                    <td>{{ selectedClients.length }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="client__list">
        <div class="table-container2">
            <table mat-table [dataSource]="selectedClients">
                <ng-container matColumnDef="clientId" class="table-header">
                    <th mat-header-cell *matHeaderCellDef>Numéro client</th>
                    <td mat-cell *matCellDef="let element">{{ element.clientId }}</td>
                </ng-container>
                <ng-container matColumnDef="firstname">
                    <th mat-header-cell *matHeaderCellDef>Prénom</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="lastname">
                    <th mat-header-cell *matHeaderCellDef>Nom</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ element.lastname }}</td>
                </ng-container>
                <ng-container matColumnDef="level">
                    <th mat-header-cell *matHeaderCellDef>Niveau</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ formatLevel(element.clientLevel) }}</td>
                </ng-container>
                <ng-container matColumnDef="sales">
                    <th mat-header-cell *matHeaderCellDef>Ventes</th>
                    <td mat-cell *matCellDef="let element" class="m-4" [ngClass]="{'column-highlight': element.total > 3000}">{{ formatPrice(element.total) }}</td>
                </ng-container>
                <ng-container *ngIf="isAdmin() || hasMultipleMachine()" matColumnDef="machine">
                    <th mat-header-cell *matHeaderCellDef>Salle</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ displayMachineLabel(element.machineId) }}</td>
                </ng-container>
                <ng-container *ngIf="!isAdmin() && !hasMultipleMachine()" matColumnDef="machine">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" class="m-4"></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectClient(row)"></tr>
            </table>
        </div>
    </div>
</div>

