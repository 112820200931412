import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { LoaderService } from './loader.service';

@Injectable()
export class SpinnerInterceptorInterceptor implements HttpInterceptor {

  private renderer: Renderer2;
  
    constructor(private spinner: LoaderService, private rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // return next.handle(request);
    const start = performance.now();
        this.showLoader();
        return next.handle(request).pipe(tap(async (event: HttpEvent < any > ) => {
                if (event instanceof HttpResponse) {
                    this.onEnd();
                }
            },
            (err: any) => {
                this.onEnd();
            }));
    }
    private onEnd(): void {
        this.hideLoader();
    }
    private showLoader(): void {
        this.spinner.showSpinner();
    }
    private hideLoader(): void {
        this.spinner.hideSpinner();
    }
}
