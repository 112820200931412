<div *ngIf="client">
    <div class="client-info">
        <div class="client__info">
            <table>
                <tbody>
                    <tr>
                        <td>Numéro</td>
                        <td>{{ client.clientId }}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{{ client.email }}</td>
                    </tr>
                    <tr>
                        <td>Prénom</td>
                        <td>{{ client.name }}</td>
                    </tr>
                    <tr>
                        <td>Nom</td>
                        <td>{{ client.lastName }}</td>
                    </tr>
                    <tr>
                        <td>Niveau</td>
                        <td>{{ formatLevel(client.clientLevel) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="datepicker">
            Date : 
            <select (change)="changeDateChoice($event)" class="date-selection">
                <option *ngFor="let date of dateChoices" [value]="date">
                    {{ 'GENERAL.DATE.'+date | translate }}
                </option>
            </select>
            <mat-form-field *ngIf="chosenDate === 'CUSTOM'">
                <mat-label>{{ 'GENERAL.DATEPICKER.SELECT_DATE' | translate }}</mat-label>
                <mat-date-range-input [rangePicker]="rangePicker">
                    <input matStartDate placeholder="Start date" [formControl]="startDate">
                    <input matEndDate placeholder="End date" [formControl]="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangePicker>
                    <mat-date-range-picker-actions>
                        <button mat-button matDateRangePickerCancel>{{ 'GENERAL.DATEPICKER.CANCEL' | translate }}</button>
                        <button mat-raised-button color="primary" matDateRangePickerApply (click)="changeDate()">{{ 'GENERAL.DATEPICKER.APPLY' | translate }}</button>
                    </mat-date-range-picker-actions>
                </mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div>
        <h3 class="title">{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.TITLE' | translate }}</h3>
        <div *ngIf="sales" class="table-container">
            <table mat-table [dataSource]="sales">
                <ng-container matColumnDef="product" class="table-header">
                    <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.PRODUCT' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        {{ formatProductName(element.product) }}
                        <span *ngIf="element.boost">(Boost)</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="taste">
                    <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.PRODUCT' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ formatProductTaste(element.taste) }}</td>
                </ng-container>
                <ng-container matColumnDef="complement">
                    <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.COMPLEMENT' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ formatProductName(element.complement) }}</td>
                </ng-container>
                <ng-container matColumnDef="water">
                    <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.WATER' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ formatWater(element.water) }}</td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.DATE' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element.date | date:'short' }}</td>
                </ng-container>
                <ng-container matColumnDef="level">
                    <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.LEVEL' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ formatLevel(element.level) }}
                        <span *ngIf="!element.clientId && element.price > 0">(en CB)</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.PRICE' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ formatPrice(element.price) }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div *ngIf="sales.length === 0">{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.NO_DATA' | translate }}</div>
        </div>
    </div>
</div>

