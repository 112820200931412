<div *ngIf="machine">
    <h1 class="title">{{ machine.label }} </h1>
    <div class="machine">
        <div class="machine__info">
            <h3 class="title">{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.INFO_TABLE.TITLE' | translate }}</h3>
            <table>
                <tbody>
                    <tr>
                        <td>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.INFO_TABLE.LOCAL' | translate }}</td>
                        <td>{{ machine.locale }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.INFO_TABLE.BUILDING_CODE' | translate }}</td>
                        <td>{{ machine.buildingCode }}</td>
                    </tr>
                    <tr *ngIf="machine.system === 'HEITZ'">
                        <td>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.INFO_TABLE.BADGE_BOX_CODE' | translate }}</td>
                        <td>{{ machine.badgeBoxCode }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.INFO_TABLE.MACHINE_STATUS' | translate }}</td>
                        <td>{{ machine.statusCode }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.INFO_TABLE.PAYMENT_CB' | translate }}</td>
                        <td>{{ machine.paymentCb ? 'Oui' : 'Non' }}</td>
                    </tr>
                    <tr>
                        <td>Eau gratuite</td>
                        <td>{{ displayWater() }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.INFO_TABLE.WATER_AVAILABLE' | translate }}</td>
                        <td>{{ displayWaterAvailable() }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="machine__product">
            <h3 class="title">{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.CONFIG_TABLE.TITLE' | translate }}</h3>
            <div class="table-container2">
            
                <table mat-table [dataSource]="configs">
                    <ng-container matColumnDef="id" class="table-header">
                        <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.CONFIG_TABLE.HEADER.BAC' | translate }}</th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{'config-element--empty': element.dose === 0}">{{ element.bac }}</td>
                    </ng-container>
                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.CONFIG_TABLE.HEADER.PRODUCT' | translate }}</th>
                        <td mat-cell *matCellDef="let element" class="m-4" [ngClass]="{'config-element--empty': element.dose === 0}">
                            {{ formatProductName(element.product) }}
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="dosesRemaining">
                        <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.CONFIG_TABLE.HEADER.DOSE' | translate }}</th>
                        <td mat-cell *matCellDef="let element" class="m-4" [ngClass]="{'config-element--empty': element.dose === 0}">{{ element.dose }}</td>
                    </ng-container>
            
                    <ng-container matColumnDef="timeRemaining">
                        <th mat-header-cell *matHeaderCellDef>temps restants estimé</th>
                        <td mat-cell *matCellDef="let element" class="m-4" [ngClass]="{'config-element--empty': element.dose === 0}">{{ estimateRemainProductDay(element.product, element.dose) }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns3; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns3"></tr>
                </table>
            </div>
            <h3 class="title">{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.STOCK_TABLE.TITLE' | translate }}</h3>
            <table>
                <thead>
                    <tr>
                        <th>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.STOCK_TABLE.HEADER.PRODUCT' | translate }}</th>
                        <th>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.STOCK_TABLE.HEADER.DOSE' | translate }}</th>
                        <th>Dernier remplissage</th>
                        <th>temps restants estimé</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let stock of stocks" [ngClass]="{'stock-element--empty': stock.dose === 0}">
                        <td>{{ formatProductName(stock.productCode) }}</td>
                        <td>{{ getNbSachetByDose(stock.productCode, stock.dose) }} sachet(s) ({{ stock.dose }} doses)</td>
                        <td>{{ getLastResetDate(stock.productCode) | date:'short' }}</td>
                        <td>{{ estimateRemainProductDay(stock.productCode, stock.dose) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="datepicker">
        {{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.DATEPICKER.TITLE' | translate }}
        <select (change)="changeDateChoice($event)" class="date-selection">
            <option *ngFor="let date of dateChoices" [value]="date">
                {{ 'GENERAL.DATE.'+date | translate }}
            </option>
        </select>
        <mat-form-field *ngIf="chosenDate === 'CUSTOM'">
            <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.DATEPICKER.SELECT_DATE' | translate }}</mat-label>
            <mat-date-range-input [rangePicker]="rangePicker">
                <input matStartDate placeholder="Start date" [formControl]="startDate">
                <input matEndDate placeholder="End date" [formControl]="endDate">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
                <mat-date-range-picker-actions>
                    <button mat-button matDateRangePickerCancel>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.DATEPICKER.CANCEL' | translate }}</button>
                    <button mat-raised-button color="primary" matDateRangePickerApply (click)="changeDate()">{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.DATEPICKER.APPLY' | translate }}</button>
                </mat-date-range-picker-actions>
            </mat-date-range-picker>
        </mat-form-field>
    </div>
    <div class="sales">
        <div class="sales__list">
            <h3 class="title">{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_LIST.TITLE' | translate }}</h3>
            <div class="table-container2">
                <table mat-table [dataSource]="totalLevels">
                    <ng-container matColumnDef="level" class="table-header">
                        <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_LIST.HEADER.LEVEL' | translate }}</th>
                        <td mat-cell *matCellDef="let element">{{ formatLevel(element.level) }}</td>
                        <td class="table-footer" mat-footer-cell *matFooterCellDef>Total</td>
                    </ng-container>
                    <ng-container matColumnDef="totalDose">
                        <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_LIST.HEADER.TOTAL_DOSE' | translate }}</th>
                        <td mat-cell *matCellDef="let element" class="m-4">{{ element.totalDose }}
                            <span *ngIf="element.totalCb > 0">(dont {{ element.totalCb }} en CB)</span>
                        </td>
                        <td class="table-footer" mat-footer-cell *matFooterCellDef>{{ sumLevelDose(totalLevels) }}</td>
                    </ng-container>
                    <ng-container matColumnDef="priceTotal">
                        <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_LIST.HEADER.TOTAL_PRICE' | translate }}</th>
                        <td mat-cell *matCellDef="let element" class="m-4">{{ formatPrice(element.totalPrice) }}</td>
                        <td class="table-footer" mat-footer-cell *matFooterCellDef>{{ formatPrice(sumPrice(totalLevels)) }}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns2"></tr>
                </table>

                <h3 class="title">Total Eau</h3>
                <table mat-table [dataSource]="totalWaters">
                    <ng-container matColumnDef="water" class="table-header">
                        <th mat-header-cell *matHeaderCellDef>Quantité</th>
                        <td mat-cell *matCellDef="let element">{{ formatWater(element.water) }}</td>
                        <td class="table-footer" mat-footer-cell *matFooterCellDef>Total</td>
                    </ng-container>
                    <ng-container matColumnDef="totalDose">
                        <th mat-header-cell *matHeaderCellDef>Total</th>
                        <td mat-cell *matCellDef="let element" class="m-4">{{ element.totalDose }}</td>
                        <td class="table-footer" mat-footer-cell *matFooterCellDef>{{ sumLevelDose(totalWaters) }}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns4; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns4"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns4"></tr>
                </table>
            </div>
        </div>
        <div class="sales__total">
            <h3 class="title">{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TOTAL.TITLE' | translate }}</h3>
            <div class="table-container2">
                <table mat-table [dataSource]="totalProducts">
                    <ng-container matColumnDef="product" class="table-header">
                        <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TOTAL.HEADER.PRODUCT' | translate }}</th>
                        <td mat-cell *matCellDef="let element">{{ formatProductName(element.product) }}</td>
                        <td class="table-footer" mat-footer-cell *matFooterCellDef>Total</td>
                    </ng-container>
                    <ng-container matColumnDef="totalDose">
                        <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TOTAL.HEADER.TOTAL_DOSE' | translate }}</th>
                        <td mat-cell *matCellDef="let element" class="m-4">{{ element.totalDose }}
                            <span *ngIf="element.totalOption > 0">( +{{ element.totalOption }} en option)</span>
                            <span *ngIf="element.totalBoost > 0">(dont {{ element.totalBoost }} boost)</span>
                        </td>
                        <td class="table-footer" mat-footer-cell *matFooterCellDef>{{ sumProductDose(totalProducts) }}</td>
                    </ng-container>
                    <ng-container matColumnDef="priceTotal">
                        <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TOTAL.HEADER.TOTAL_PRICE' | translate }}</th>
                        <td mat-cell *matCellDef="let element" class="m-4">{{ formatPrice(element.totalPrice) }}</td>
                        <td class="table-footer" mat-footer-cell *matFooterCellDef>{{ formatPrice(sumPrice(totalProducts)) }}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns1"></tr>
                </table>
            </div>
        </div>
    </div>
    <h3 class="title">{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.TITLE' | translate }}</h3>
    <div *ngIf="sales" class="table-container">
        <table mat-table [dataSource]="sales">
            <ng-container matColumnDef="product" class="table-header">
                <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.PRODUCT' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ formatProductName(element.product) }}
                    <span *ngIf="element.boost">(Boost)</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="taste">
                <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.PRODUCT' | translate }}</th>
                <td mat-cell *matCellDef="let element" class="m-4">{{ formatProductTaste(element.taste) }}</td>
            </ng-container>
            <ng-container matColumnDef="complement">
                <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.COMPLEMENT' | translate }}</th>
                <td mat-cell *matCellDef="let element" class="m-4">{{ formatProductName(element.complement) }}</td>
            </ng-container>
            <ng-container matColumnDef="water">
                <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.WATER' | translate }}</th>
                <td mat-cell *matCellDef="let element" class="m-4">{{ formatWater(element.water) }}</td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.DATE' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.date | date:'short' }}</td>
            </ng-container>
            <ng-container matColumnDef="level">
                <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.LEVEL' | translate }}</th>
                <td mat-cell *matCellDef="let element" class="m-4">{{ formatLevel(element.level) }}
                    <span *ngIf="!element.clientId && element.price > 0">(en CB)</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.SALES_TABLE.HEADER.PRICE' | translate }}</th>
                <td mat-cell *matCellDef="let element" class="m-4">{{ formatPrice(element.price) }}</td>
            </ng-container>
            <ng-container matColumnDef="client">
                <th mat-header-cell *matHeaderCellDef>Client</th>
                <td mat-cell *matCellDef="let element" class="m-4 clickable"><a href="admin/client/{{element.clientId}}">{{ element.clientId && element.clientId !== '0' ? element.clientId : '' }}</a></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="sales.length === 0">{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.NO_DATA' | translate }}</div>
    </div>
    <div *ngIf="isSuperAdmin()">
        <app-logs [machineId]="machine.identifier"></app-logs>
    </div>
</div>