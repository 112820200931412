import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StorageService } from 'src/app/services/storage.service';
import { MachineService } from '../../service/machine.service';
import { TicketService } from '../../service/ticket.service';
import { CommandService } from '../../service/command.service';
import { Machine } from '../../models/machine.model';
import { BuildingService } from '../../service/building.service';

@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss']
})
export class DialogContentComponent implements OnInit {

  action: string = '';
  ticketData: any;
  commandData: any
  // tslint:disable-next-line - Disables all
  displayedColumns: string[] = [
    'code',
    'quantity',
    'price',
  ];
  local_data: any;
  ticketStatus = new FormControl('', [Validators.required]);
  ticketStatuses = ['Unprocessed', 'In process', 'Already processed'];
  commandStatus = new FormControl('', [Validators.required]);
  commandStatuses = ['WAITING_FOR_PAYMENT', 'WAITING_FOR_VALIDATION', 'PAID'];
  buildingList : any[] = [];
  productList : any[] = [];
  public ticketForm!: FormGroup;
  public commandForm!: FormGroup;
  public preview: string = '';
  public machineData: any;
  public machines: Machine[] = [];
  public machineId: number = 0;
  public productFieldCount = 0;
  public user: any;
  constructor(
    public dialogRef: MatDialogRef<DialogContentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public machineService: MachineService,
    public ticketService: TicketService,
    public CommandService: CommandService,
    public buildingService: BuildingService,
    private storage: StorageService,
  ) {
    this.user = this.storage.get('user');
    console.log(this.user);
    if (data.type === 'ticket') {
      this.ticketData = data.data;
      if (this.ticketData === 'Add') {
        this.getMachineDetails();
        this.action = this.ticketData;
      } else {
        this.ticketStatus.setValue(this.ticketData.status);
        this.local_data = { ...this.ticketData };
        this.action = this.local_data.action;

      }
      this.ticketForm = this.fb.group({
        title: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required]),
        image: new FormControl(),
      });
    } else if(data.type === 'command'){
      this.commandData = data.data;
      if (this.commandData === 'Add') {
        this.getBuildingsAndProductList();
        this.action = this.commandData;
      } else {
        this.commandStatus.setValue(this.commandData.status);
        this.local_data = { ...this.commandData };
        this.action = this.local_data.action;
      }
      this.commandForm = this.fb.group({
        commandNumber: new FormControl(''),
        buildingID: new FormControl('', [Validators.required]),
        price:  new FormControl(0, [Validators.required]),
        tva:  new FormControl(5.5, [Validators.required]),
        products: new FormArray([])
      });
    }
  }

  ngOnInit(): void {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.closeDialog();
      }
    });

    this.dialogRef.backdropClick().subscribe(event => {
      this.closeDialog();
    });
    if(this.data.type === 'command'){}
  }
  
  public getTitle (): string {
    if (this.action === 'Add') {
      if (this.data.type === 'command') {
        return 'Nouvelle commande';
      } else if (this.data.type === 'ticket') {
        return 'Nouveau ticket';
      }
    } else if (this.action === 'Update') {
      if (this.data.type === 'command') {
        return 'Modification de la commande';
      } else if (this.data.type === 'ticket') {
        return 'Modification du ticket';
      }
    }
    return '';
  }

  public formatProductName (productName: string) {
    switch (productName) {
        case 'WATER':
            return 'eau';
        case 'CREATINE':
            return 'Créatine';
        case 'PROTEIN_ISOLATE':
            return 'Protéine Isolate';
        case 'PROTEIN_ISOLATE_VANILLA':
            return 'Protéine Isolate vanille';
        case 'PROTEINE_ISOLATE_VANILLE':
            return 'Protéine Isolate vanille';
        case 'PROTEIN_ISOLATE_CHOCOLATE':
            return 'Protéine Isolate chocolat';
        case 'PROTEINE_ISOLATE_CHOCOLAT':
            return 'Protéine Isolate chocolat';
        case 'PROTEIN_ISOLATE_COCO':
            return 'Protéine Isolate coco';
        case 'PROTEINE_ISOLATE_COCO':
            return 'Protéine Isolate coco';
        case 'PROTEIN_ISOLATE_COOKIE':
            return 'Protéine Isolate cookie';
        case 'PROTEINE_ISOLATE_COOKIE':
            return 'Protéine Isolate cookie';
        case 'PROTEIN_ISOLATE_STRAWBERRY':
            return 'Protéine Isolate Fraise';
        case 'PUMP_MIXED_BERRIES':
            return 'PUMP fruits des bois';
        case 'PUMP_FRUIT_PUNCH':
            return 'PUMP fruits punch';
        case 'PUMP_PUNCH_FRUIT':
            return 'PUMP fruits punch';
        case 'PROTEIN':
            return 'Protéine';
        case 'PROTEIN_VANILLA':
            return 'Protéine vanille';
        case 'PROTEIN_CHOCOLATE':
            return 'Protéine chocolat';
        case 'GAINEUR':
            return 'Gaineur';
        case 'GAINEUR_VANILLA':
            return 'Gaineur vanille';
        default:
            return productName;
    }
  }

  public getimageFromProduct (productName: string) {
    switch (productName) {
        case 'CREATINE':
            return '/assets/images/creatine.png';
        case 'PROTEIN_ISOLATE':
        case 'PROTEIN_ISOLATE_VANILLA':
        case 'PROTEINE_ISOLATE_VANILLE':
        case 'PROTEIN_ISOLATE_CHOCOLATE':
        case 'PROTEINE_ISOLATE_CHOCOLAT':
        case 'PROTEIN_ISOLATE_COCO':
        case 'PROTEINE_ISOLATE_COCO':
        case 'PROTEIN_ISOLATE_COOKIE':
        case 'PROTEINE_ISOLATE_COOKIE':
        case 'PROTEIN_ISOLATE_STRAWBERRY':
          return '/assets/images/proteine.png';
        case 'PUMP_MIXED_BERRIES':
        case 'PUMP_FRUIT_PUNCH':
        case 'PUMP_PUNCH_FRUIT':
          return '/assets/images/preworkout.png';
        case 'BCAA':
          return '/assets/images/bcaa.png';
        default:
          return '/assets/images/proteine.png';
    }
  }

  public formatPrice (price: number) {
      if (!price) {
          return "";
      }
      var priceInEuro = price / 100; 
      return priceInEuro.toFixed(2) + ' €';
  }

  public isAdmin (): boolean {
      return this.user?.status == 'ADMIN' || this.user?.status == 'SUPERADMIN';
  }

  public selectMachine (event: any) {
    var machine: number = event.target.value;
    this.machineData.id = machine;
  }

  getMachineDetails() {
    if (this.isAdmin()) {
      this.machineService.getMachines(this.isAdmin() ? null : this.user.machineCode).subscribe({
        next: (machines) => {
            this.machines = machines;
            if (this.machines.length > 0) {
              this.machineData = this.machines[0];
            }
        },
        error: (e) => console.error(e)
      });
    } else {
      let data, tempData;
      this.machineService.machineData.subscribe((resp: any) => {
        data = resp;
      });
      tempData = sessionStorage.getItem('machineData');
      data ? this.machineData = JSON.parse(data) : this.machineData = JSON.parse(tempData ? tempData : '{}');
    }
  }
  getBuildingsAndProductList(){
    this.CommandService.getProducts().subscribe((resp: any) => {
      this.productList = resp;
      const productForm = this.commandForm.get('products') as FormArray;
      this.productList.forEach(product => {
        productForm.push(this.createProductArray(product.code));
      })
    });
    this.buildingService.getBuildings(this.isAdmin() ? null : this.user.machineCode).subscribe((resp: any) => {
      this.buildingList = resp;
      if (this.buildingList.length == 1) {
        this.commandForm.get('buildingID')?.setValue(this.buildingList[0].id);
      }
    })
  }
  onSubmit() {
    // Handle form submission here (e.g., send data to a server)
    if(this.data.type === 'ticket'){
    if (this.ticketForm.valid) {
      let data = this.ticketForm.value;
      data['machine_id'] = this.machineData.id;
      
      this.dialogRef.close({ event: this.action, data: data });
    }
  } else if(this.data.type === 'command'){
    if (this.commandForm.valid) {
      let data = this.commandForm.value;
      this.dialogRef.close({ event: this.action, data: data });
    }
  }
  }

  onImageChange(event: any) {
    const file = event.target.files[0];
    this.ticketForm.patchValue({
      image: file
    });
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.preview = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  doAction(): void {
    if (this.action === 'Add') {
      this.onSubmit();
    }
    else if (this.action === 'Update') {
      this.local_data.status = this.data.type === 'ticket' ? this.ticketStatus.value : this.commandStatus.value;
      this.dialogRef.close({ event: this.action, data: this.local_data });
    } else {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  addMoreProducts(){
    const productForm = this.commandForm.get('products') as FormArray;
    productForm.push(this.createProductArray());
  }

  deleteProduct(index: any) {
    const productForm = this.commandForm.get('products') as FormArray;
    productForm.removeAt(index);
  }

  createProductArray(code : string = '') {
    return new FormGroup({
      'code' : new FormControl(code, [Validators.required]),
      'quantity' :  new FormControl(0, [Validators.required]),
      'price' :  new FormControl(0, [Validators.required])
    });
  }
  get productFormControls() {
    return (this.commandForm.get('products') as FormArray).controls;
  }

  public decreaseQuantity (index : number) {
    const productForm = this.commandForm.get('products') as FormArray;
    const productFromArray = productForm.at(index);
    if (productFromArray.value['quantity'] > 0) {
      productFromArray.value['quantity']--;
    }
  }

  public increaseQuantity (index : number) {
    const productForm = this.commandForm.get('products') as FormArray;
    const productFromArray = productForm.at(index);
    const maxQuantity = productFromArray.value['code'].includes('PROTEIN') ? 4 : 2;
    if (productFromArray.value['quantity'] < maxQuantity) {
      productFromArray.value['quantity']++;
    }
  }

  public showProductPrice (index : number) {
    const productForm = this.commandForm.get('products') as FormArray;
    const productFromArray = productForm.at(index);
    const productCode = productFromArray.value['code'];
    const quantity = productFromArray.value['quantity'];
    const myProducts = this.productList.filter(product => product.code == productCode);
    if (myProducts.length == 1) {
      var price = myProducts[0].buyPriceHt;

      productFromArray.value['price'] = price * quantity;

      return this.formatPrice(price * quantity);
    }
    return '';
  }

  public showProductTotal (totalTTC : boolean) {
    var total = 0;
    if (this.action === 'View') {
      total = this.local_data.price;

      if (totalTTC) {
        total += (total * this.local_data.tva) / 100;
      }
    } else if (this.action === 'Add') {
      const productForm = this.commandForm.get('products') as FormArray;
      for (var i = 0 ; i < productForm.length ; i++) {
        const productFromArray = productForm.at(i);
        const productCode = productFromArray.value['code'];
        const quantity = productFromArray.value['quantity'];
        const myProducts = this.productList.filter(product => product.code == productCode);
        if (myProducts.length == 1) {
          var price = myProducts[0].buyPriceHt;

          if (totalTTC) {
            price += (price * myProducts[0].tva) / 100;
          }

          total += price * quantity;
        }
      }

      if (!totalTTC) {
        this.commandForm.get('price')?.setValue(total);
      }
      
    }
    return total;
  }

  public formatStatus (status: string): string {
      if (status == 'Unprocessed') {
        return 'En attente de traitement';
      }
      if (status == 'In process') {
        return 'En cours';
      }
      if (status == 'Already processed') {
        return 'Terminé';
      }
      if (status == 'PAID') {
        return 'Payée';
      }
      if (status == 'WAITING_FOR_VALIDATION') {
        return 'En attente de validation';
      }
      if (status == 'WAITING_FOR_PAYMENT') {
        return 'En attente de paiement';
      }
      return status;
  }
}
