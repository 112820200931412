import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { Stock } from '../models/stock.model';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  protected URL: string = environment.backendApi;
  constructor(private httpClient: HttpClient, private storage: StorageService) { }

  public getToken(){
    let data = this.storage.get('user');
    return data;
  }
 

  public getStock(buildingCode : string): Observable<Stock[]> {
    let loginData = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.get<Stock[]>(`${this.URL}/stock/${buildingCode}`, httpOptions);
  }
 

  public getAllStock(): Observable<Stock[]> {
    let loginData = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.get<Stock[]>(`${this.URL}/stocks`, httpOptions);
  }
}
