import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { ClientService } from '../../../service/client.service';
import { MachineService } from '../../../service/machine.service';
import { Machine } from '../../../models/machine.model';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {

  public clients: any[] = [];
  public machines: Machine[] = [];
  public selectedClients: any[] = [];
  public user: any;
  public totalLevel0: number = 0;
  public totalSubscribe: number = 0;
  public totalManager: number = 0;
  public totalEmployee: number = 0;
  public totalWater: number = 0;
  public totalOther: number = 0;

  displayedColumns: string[] = [
    'clientId',
    'firstname',
    'lastname',
    'level',
    'sales',
    'machine'
  ];

  constructor(private clientService: ClientService, private machineService: MachineService, private storage: StorageService, private _router: Router) {
  }

  ngOnInit() {
      this.user = this.storage.get('user');
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 30);
      
      this.clientService.getClients(currentDate, this.isAdmin() ? null : this.user.machineCode).subscribe({
          next: (clients) => {
              this.clients = clients.sort((a: any, b: any) => b.machineId - a.machineId || b.total - a.total);
              this.selectedClients = this.clients;
              this.countClientLevel();
          },
          error: (e) => console.error(e)
      });
      this.machineService.getMachines(this.isAdmin() ? null : this.user.machineCode).subscribe({
        next: (machines) => {
            this.machines = machines;
        },
        error: (e) => console.error(e)
    });
  }

  public isAdmin (): boolean {
      return this.user?.status == 'ADMIN' || this.user?.status == 'SUPERADMIN';
  }

  public hasMultipleMachine (): boolean {
    return this.user?.machineCode.length > 1;
  }

  public formatLevel (level: string) {
      switch (level) {
          case 'LEVEL0':
              return 'Non abonné';
          case 'LEVEL1':
          case 'LEVEL2':
              return 'Abonné';
          case 'LEVEL3':
              return 'Manager';
          case 'LEVEL4':
              return 'Employé';
          case 'LEVEL5':
              return 'Abonné eau uniquement';
          default:
              return level;
      }
  }

  public formatPrice (price: number) {
      if (!price) {
          return "";
      }
      var priceInEuro = price / 100; 
      return priceInEuro.toFixed(2) + ' €';
  }

  public displayMachineLabel (machineId: number) {
    const machineFound = this.machines.filter(machine => machine.id === machineId);

    return machineFound.length === 0 ? '' : machineFound[0].label;
  }

  public selectClient (client: any): void {
    this._router.navigate(
      ['admin/client', client.clientId]
    );
  }

  public filterMachine (event: any) {
    var machine: number = event.target.value;
    if (machine == 0) {
      this.selectedClients = this.clients;
    } else {
      this.selectedClients = this.clients.filter(
        client => client.machineId == machine
      );
    }
    
    this.selectedClients = this.selectedClients.sort((a: any, b: any) => a.clientLevel.localeCompare(b.clientLevel) || b.total - a.total);
    this.countClientLevel();
  }

  private countClientLevel () {
    this.totalLevel0 = this.selectedClients.filter(
      client => client.clientLevel === 'LEVEL0'
    ).length;
    
    this.totalSubscribe = this.selectedClients.filter(
      client => (client.clientLevel === 'LEVEL1' || client.clientLevel === 'LEVEL2')
    ).length;
    
    this.totalManager = this.selectedClients.filter(
      client => client.clientLevel === 'LEVEL3'
    ).length;
    
    this.totalEmployee = this.selectedClients.filter(
      client => client.clientLevel === 'LEVEL4'
    ).length;
    
    this.totalWater = this.selectedClients.filter(
      client => client.clientLevel === 'LEVEL5'
    ).length;

    this.totalOther = this.selectedClients.filter(
      client => client.clientLevel === ''
    ).length;
  }

}

