<div class="flex p-8">
  <div class="m-auto w-full sm:w-1/2 lg:w-1/3">
    <div class="bg-white p-8 shadow-md rounded-md">
      <h2 class="text-3xl font-extrabold mb-16">{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.TITLE' | translate }}</h2>
      <form [formGroup]="form" (ngSubmit)="createMachine()">
        <div class="mb-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.LABEL_INPUT' | translate }}</mat-label>
            <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.LABEL_PLACEHOLDER' | translate }}" formControlName="label">
          </mat-form-field>
        </div>
        <div class="mb-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.DESCRIPTION_INPUT' | translate }}</mat-label>
            <textarea matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.DESCRIPTION_INPUT' | translate }}" formControlName="description"></textarea>
          </mat-form-field>
        </div>
        <div class="mb-4">
          <mat-form-field class="m-2">
            <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.LOCAL' | translate }} </mat-label>
            <mat-select formControlName="locale">
                <mat-option *ngFor="let locale of locales" [value]="locale">
                  {{ locale }}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mb-4">
          <mat-form-field class="m-2">
            <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.PAYMENT_CB' | translate }} </mat-label>
            <mat-select formControlName="paymentCB">
                <mat-option *ngFor="let paymentCbValue of yesNoValues" [value]="paymentCbValue">
                  {{ 'GENERAL.' + paymentCbValue | translate }}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mb-4">
          <mat-form-field class="m-2">
            <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.WATER_AVAILABLE' | translate }} </mat-label>
            <mat-select formControlName="waterAvailable">
                <mat-option *ngFor="let waterAvailableValue of yesNoValues" [value]="waterAvailableValue">
                  {{ 'GENERAL.' + waterAvailableValue | translate }}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mb-4">
          <mat-form-field class="m-2">
            <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.WATER_FREE' | translate }} </mat-label>
            <mat-select formControlName="waterFree">
                <mat-option *ngFor="let waterFreeValue of yesNoValues" [value]="waterFreeValue">
                  {{ 'GENERAL.' + waterFreeValue | translate }}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mb-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CONTACT_INPUT' | translate }}</mat-label>
            <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CONTACT_INPUT' | translate }}" formControlName="contact">
          </mat-form-field>
        </div>
        <div class="mb-4">
          <mat-form-field appearance="fill" class="w-1/2 m-2">
            <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.BUILDING' | translate }}</mat-label>
            <mat-select formControlName="building" required>
                <mat-option *ngFor="let building of buildingList"
                    [value]="building['id']">
                    {{building['name']}} - {{building['code']}}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mb-4">
          <mat-form-field class="m-2">
            <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.SYSTEM' | translate }} </mat-label>
            <mat-select formControlName="system">
                <mat-option *ngFor="let system of systems" [value]="system">
                  {{ system }}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="form?.get('system')?.value === 'HEITZ'">
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.API_URL_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.API_URL_PLACEHOLDER' | translate }}" formControlName="apiUrl">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.BADGE_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.BADGE_INPUT' | translate }}" formControlName="badgeBoxCode">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.API_KEY_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.API_KEY_INPUT' | translate }}" formControlName="apiKey">
            </mat-form-field>
            <button type="button"
              (click)="generateApiKey()"
              class="">{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.API_KEY_BUTTON' | translate }}
            </button>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CODE_CENTRE_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CODE_CENTRE_INPUT' | translate }}" formControlName="codeCentre">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLIENT_ID_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLIENT_ID_INPUT' | translate }}" formControlName="heitzClientId">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.KEY_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.KEY_INPUT' | translate }}" formControlName="heitzKey">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.ARTICLE_ID_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.ARTICLE_ID_INPUT' | translate }}" formControlName="idArticle">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.TARIF_ID_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.TARIF_ID_INPUT' | translate }}" formControlName="idTarrif">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.PAYMENT_ID_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.PAYMENT_ID_INPUT' | translate }}" formControlName="idPayment">
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="form?.get('system')?.value === 'DECIPLUS'">
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLIENT_ID_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLIENT_ID_INPUT' | translate }}" formControlName="clientId">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLIENT_SECRET_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLIENT_SECRET_INPUT' | translate }}" formControlName="clientSecret">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.DOMAIN_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.DOMAIN_INPUT' | translate }}" formControlName="domain">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.ZONE_ID_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.ZONE_ID_INPUT' | translate }}" formControlName="zoneId">
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="form?.get('system')?.value === 'SPORTIGO'">
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.COMPANY_ID_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.COMPANY_ID_INPUT' | translate }}" formControlName="companyId">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.API_KEY_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.API_KEY_INPUT' | translate }}" formControlName="apiKey">
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="form?.get('system')?.value === 'RESAMANIA'">
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLIENT_TOKEN_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLIENT_TOKEN_INPUT' | translate }}" formControlName="clientToken">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.API_KEY_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.API_KEY_INPUT' | translate }}" formControlName="apiKey">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLIENT_ID_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLIENT_ID_INPUT' | translate }}" formControlName="clientId">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLIENT_SECRET_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLIENT_SECRET_INPUT' | translate }}" formControlName="clientSecret">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLUB_ID_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLUB_ID_INPUT' | translate }}" formControlName="clubId">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.NODE_ID_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.NODE_ID_INPUT' | translate }}" formControlName="nodeId">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLUB_CODE_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CLUB_CODE_INPUT' | translate }}" formControlName="clubCode">
            </mat-form-field>
          </div>
        </div>
        <div class="mb-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.MACHINE_PORT' | translate }}</mat-label>
            <input matInput formControlName="machinePort">
          </mat-form-field>
        </div>
        <div class="mb-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.PAYMENT_PORT' | translate }}</mat-label>
            <input matInput formControlName="paymentPort">
          </mat-form-field>
        </div>
        <button type="submit"
          class="w-full bg-teal-400 text-black border-2 border-black font-bold py-2 px-4 rounded-md ">{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.SUBMIT' | translate }}
        </button>
          <button type="button"
            (click)="cancel()"
            class="w-full text-black border-2 border-black font-bold py-2 px-4 rounded-md ">{{ 'MODULES.ADMINISTRATION.MACHINE.FORM.CANCEL' | translate }}
          </button>
      </form>
    </div>
  </div>
</div>