<div class="flex h-screen p-8">
  <div class="m-auto w-full sm:w-1/2 lg:w-1/3">
    <div class="bg-white p-8 shadow-md rounded-md">
      <h2 class="text-3xl font-extrabold mb-16">Login</h2>
      <form [formGroup]="form" (ngSubmit)="processLogin()">
        <div class="mb-6">
          <label class="ml-4 text-2xl	font-semibold	">Username</label>
          <input type="text" class="w-full" placeholder="Enter Username" formControlName="login">
        </div>
        <div class="mb-6">
          <label class="ml-4 text-2xl	font-semibold	">Password</label>
          <input placeholder="Enter Password" class="w-full" type="password" formControlName="password">
        </div>
        <button type="submit"
          class="w-full bg-teal-400 text-black font-bold mt-4">Login</button>
      </form>
    </div>
  </div>
</div>