<div class="logs">
    <h2 class="title">Logs</h2>
   
    <section class="table-container">

    <table mat-table [dataSource]="dataSource">

        <!-- Define the columns -->
        <ng-container matColumnDef="date" >
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">{{ element.date }}</td>
        </ng-container>
      
        <ng-container matColumnDef="level" >
          <th mat-header-cell *matHeaderCellDef>Level</th>
          <td mat-cell *matCellDef="let element">{{ element.level }}</td>
        </ng-container>
      
        <ng-container matColumnDef="message" >
          <th mat-header-cell *matHeaderCellDef >Message</th>
          <td mat-cell *matCellDef="let element">{{ element.message }}</td>
        </ng-container>
      
        <!-- Set the header row -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      
        <!-- Set the row template -->
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      </section>
</div>


