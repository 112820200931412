import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { MachineService } from '../../../service/machine.service';
import { Machine } from '../../../models/machine.model';
import { MailService } from '../../../service/mail.service';
import { Mail } from '../../../models/mail.model';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss']
})
export class MailComponent implements OnInit {

  public mails: Mail[] = [];
  public machines: Machine[] = [];
  public selectedMails: Mail[] = [];
  public user: any;
  public totalLevel0: number = 0;
  public totalSubscribe: number = 0;
  public totalManager: number = 0;
  public totalEmployee: number = 0;
  public totalWater: number = 0;
  public totalOther: number = 0;

  public startDate: Date = new Date();

  displayedColumns: string[] = [
    'title',
    'mailto',
    'date',
    'machine'
  ];

  constructor(private mailService: MailService, private machineService: MachineService, private storage: StorageService, private _router: Router) {
  }

  ngOnInit() {
      this.user = this.storage.get('user');
      this.mailService.getAllMail().subscribe({
          next: (mails) => {
              this.mails = mails.sort((a: Mail, b: Mail) => b.id - a.id);
              this.selectedMails = this.mails;
          },
          error: (e) => console.error(e)
      });
      this.machineService.getMachines(this.isAdmin() ? null : this.user.machineCode).subscribe({
        next: (machines) => {
            this.machines = machines;
        },
        error: (e) => console.error(e)
    });
  }

  public isAdmin (): boolean {
      return this.user?.status == 'ADMIN' || this.user?.status == 'SUPERADMIN';
  }

  public displayMachineLabel (machineId: number) {
    const machineFound = this.machines.filter(machine => machine.id === machineId);

    return machineFound.length === 0 ? '' : machineFound[0].label;
  }

  public hasMultipleMachine (): boolean {
    return this.user?.machineCode.length > 1;
  }

  public selectMail (mail: Mail): void {
    this._router.navigate(
      ['admin/mail', mail.id]
    );
  }

  public filterMachine (event: any) {
    var machine: number = event.target.value;
    if (machine == 0) {
      this.selectedMails = this.mails;
    } else {
      this.selectedMails = this.mails.filter(
        mail => mail.machineId == machine
      );
    }
    
    this.selectedMails = this.selectedMails.sort((a: Mail, b: Mail) => b.date.getTime() - a.date.getTime());
  }

}

