<div *ngIf="user">
    <div class="account-info">
        <div class="account__info">
            <table>
                <tbody>
                    <tr>
                        <td>Email</td>
                        <td>{{ user.email }}</td>
                    </tr>
                    <tr>
                        <td>Nom</td>
                        <td>{{ user.name }}</td>
                    </tr>
                    <tr>
                        <td>Prénom</td>
                        <td>{{ user.last_name }}</td>
                    </tr>
                    <tr>
                        <td>status</td>
                        <td>{{ user.status }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="account__password">
            <button
                *ngIf="!isPasswordEnable"
                class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m text-lg"
                color="primary" mat-raised-button
                (click)="enablePasswordUpdate()">
                Modifier mon mot de passe
            </button>

            <form *ngIf="isPasswordEnable" [formGroup]="form" (ngSubmit)="updatePassword()">
                <span class="error" *ngIf="hasFormError">Le formulaire n'est pas valide</span>
                <span class="error" *ngIf="passwordNotSame">Les 2 mots de passe sont différents</span>
                <div class="mb-6">
                  <label class="ml-4 text-2xl font-semibold">Nouveau mot de passe</label>
                  <input placeholder="Nouveau mot de passe" class="w-full" type="password" formControlName="password">
                </div>
                <div class="mb-6">
                    <label class="ml-4 text-2xl	font-semibold">Confirmer le mot de passe</label>
                    <input placeholder="Confirmer le mot de passe" class="w-full" type="password" formControlName="confirmPassword">
                </div>
                <button type="submit"
                  class="w-full bg-teal-400 text-black font-bold mt-4">Valider</button>
                <button type="button" (click)="cancelPasswordUpdate()"
                    class="w-full text-black border-2 border-black font-bold py-2 px-4 rounded-md ">Annuler
                </button>
              </form>
        </div>
    </div>
</div>

