<div class="row">
    <h2 class="title">Factures</h2>
    <div class="machine" *ngFor="let machine of machines">
        <h3 class="title">{{ machine.label }}</h3>
        <ul class="invoice">
            <li *ngFor="let invoice of machine.invoices">
                <a href="{{ getFullUrl(invoice) }}" target="_blank">{{ invoice.name }}</a>
            </li>
        </ul>
    </div>
</div>

