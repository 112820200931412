import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, retry } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { SnackbarService } from './snackbar.service';

export const maxRetries = 2;
export const delayMs = 2000;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  currentUrl: string = '';
  constructor(private router: Router, private storage: StorageService, private snackBar: SnackbarService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(retry(1), catchError((error) => this.errorHandler(error)))
  }

  private errorHandler(response: HttpErrorResponse): Observable < never > {
    this.currentUrl = this.router.url;
    // console.error(error);
    const httpErrorCode = response.status;
    switch (httpErrorCode) {
      case 401:
        this.router.navigateByUrl('/');
        this.storage.remove('user');
        this.snackBar.openSnackBar(response.error.message, 'Failure');
        break;
      default:
        console.error(response);
        this.snackBar.openSnackBar(response.error.message, 'Failure', 5000);
        this.router.navigateByUrl(`/${this.currentUrl}`);
        break;
    }

    throw response;
  }
}
