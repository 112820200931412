<!-- table -->
<mat-card *ngIf="dataSource.data" class="table-container">
    <table mat-table [dataSource]="dataSource.data">
        <ng-container matColumnDef="id" class="table-header">
            <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.COMMAND.ID' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="commandNumber">
            <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.COMMAND.COMMAND_NUMBER' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="m-4">{{ element.commandNumber }}</td>
        </ng-container>
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.COMMAND.BUILDING_CODE' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="m-4">{{ element.code }}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.COMMAND.BUILDING_NAME' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.COMMAND.STATUS' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="justify-center" class="m-4">
                <div [ngClass]="element.status"
                    class="inline-flex  text-white justify-center rounded px-2 py-1 status_button">
                    <span class="text-lg  font-semibold">{{ formatStatus(element.status) }}</span>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="custom-action-header flex flex-row justify-end">
                <span class="mt-4">
                    Actions</span>
                <div class="mt-2 ml-4">
                    <button mat-flat-button matTooltip="{{ 'MODULES.ADMINISTRATION.COMMAND.ACTIONS.ADD' | translate }}" color="primary" (click)="openDialog('Add')">
                        <mat-icon>add</mat-icon>
                        {{ 'MODULES.ADMINISTRATION.COMMAND.ACTIONS.ADD' | translate }}
                    </button>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <button mat-flat-button color="primary"
                    (click)="openDialog('View', element)"><mat-icon>visibility</mat-icon></button>
                <button mat-flat-button class="bg-amber-500 text-white" *ngIf="isAdmin() && element.status !== 'PAID'"
                    (click)="openDialog('Update', element)"><mat-icon>edit</mat-icon></button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</mat-card>
