import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

    public form: FormGroup;

    constructor(private loginService: LoginService, private router: Router, private storage: StorageService, private snackBar: SnackbarService) { 
        this.form = new FormGroup({
            login: new FormControl(),
            password: new FormControl(),
        });
    }

    ngOnInit() {
        let userData = this.storage.get('user');
        if (userData?.status === 'MANAGER') {
            if (userData.machineCode.length > 0) {
                if (userData.machineCode.length == 1) {
                    this.router.navigate(['admin/machine/' + userData.machineCode[0]]);
                } else {
                    this.router.navigate(['admin/machines']);
                }
            } else {
                // TODO : redirect to account page
                this.router.navigate(['admin/ticket']);
            }
        } else if (userData?.status === 'ADMIN' || userData?.status === 'SUPERADMIN') {
            this.router.navigate(['admin/machines']);
        } else {
            this.router.navigate(['login']);
        }
    }

    public processLogin(): void {
        if (this.form.valid) {
            const login = {
                login: this.form.get("login")?.value,
                password: this.form.get("password")?.value,
                platform: 'web'
            }
            this.loginService.login(login).subscribe({
                next: (user) => {
                    var userData = user.data;
                    this.storage.set('user', userData);
                    this.loginService.user.next(JSON.stringify(userData));
                    if (userData?.status === 'MANAGER') {
                        if (userData.machineCode.length > 0) {
                            if (userData.machineCode.length == 1) {
                                this.router.navigate(['admin/machine/' + userData.machineCode[0]]);
                            } else {
                                this.router.navigate(['admin/machines']);
                            }
                        } else {
                            // TODO : redirect to account page
                            this.router.navigate(['admin/ticket']);
                        }
                    } else {
                        this.router.navigate(['admin/machines']);
                    }
                },
                error: (e) => {
                    console.error(e);
                    this.snackBar.openSnackBar(e.error.message, 'Failure', 5000);
                }
            });
        }
    }


}
