import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Machine } from 'src/app/modules/administration/models/machine.model';
import { ProductSale, ProductSaleTotal, LevelSaleTotal, WaterSaleTotal } from 'src/app/modules/administration/models/product-sales.model';
import { MachineService } from '../../../../service/machine.service';
import { MachineConfig } from '../../../../models/config.model';
import { MachineModel } from '../../../../models/machine-model.model';
import { StockService } from '../../../../service/stock.service';
import { Stock } from '../../../../models/stock.model';
import { StorageService } from 'src/app/services/storage.service';
import { ResetDose } from 'src/app/modules/administration/models/reset-dose.model';
import { ProductStat, Stat } from 'src/app/modules/administration/models/stat.model';
import { StatService } from 'src/app/modules/administration/service/stat.service';

@Component({
  selector: 'app-machine-detail',
  templateUrl: './machine-detail.component.html',
  styleUrls: ['./machine-detail.component.scss']
})
export class MachineDetailComponent implements OnInit {

    public machine!: MachineModel;
    public sales: ProductSale[] = [];
    public configs: MachineConfig[] = [];
    public stocks: Stock[] = [];
    public resetDoses: ResetDose[] = [];
    public totalProducts: ProductSaleTotal[] = [];
    public totalLevels: LevelSaleTotal[] = [];
    public totalWaters: WaterSaleTotal[] = [];
    public user: any;
    public statsMachine!: Stat;
    public statsProduct: ProductStat[] = [];
    public startDate: FormControl = new FormControl(new Date());
    public endDate: FormControl = new FormControl(new Date());
    public dateChoices: string[] = [
        "TODAY", "THIS_WEEK", "THIS_MONTH", "LAST_MONTH", "CUSTOM"
    ];
    public chosenDate: string = "TODAY";
    
    private id: string|null = null;
    displayedColumns: string[] = [
        'product',
        'taste',
        'complement',
        'water',
        'date',
        'level',
        'price',
        'client'
      ];

      displayedColumns1: string[] = [
        'product',
        'totalDose',
        'priceTotal'
      ];

      displayedColumns2: string[] = [
        'level',
        'totalDose',
        'priceTotal'
      ];

      displayedColumns3: string[] = [
        'id',
        'product',
        'dosesRemaining',
        'timeRemaining'
      ];

      displayedColumns4: string[] = [
        'water',
        'totalDose'
      ];
    constructor(private machineService: MachineService,
        private stockService: StockService, 
        private statService: StatService, 
        private _Activatedroute:ActivatedRoute,
        private storage: StorageService,
        private _router: Router
    ) {
    }

    ngOnInit() {
        this.user = this.storage.get('user');
        this.startDate.value.setDate(this.startDate.value.getDate());
        this.endDate.value.setDate(this.endDate.value.getDate());
        this._Activatedroute.paramMap.subscribe(paramMap => { 
            this.id = paramMap.get('id');
            if (this.id !== null) {
                this.machineService.getMachineModel(this.id).subscribe({
                    next: (machine) => {
                        this.machine = machine;
                        this.machineService.machineData.next(JSON.stringify(machine));
                        sessionStorage.setItem('machineData', JSON.stringify(machine));
                        this.configs = this.machine.configs;
                        this.configs.sort((a, b) => a.bac - b.bac);
                        this.loadStock();
                        this.loadResetDose();
                    },
                    error: (e) => console.error(e)
                });
                this.loadMachineSales();
                this.loadMachineStat();
                this.loadProductStat();
            }
        });
        
    }

    public isAdmin (): boolean {
        return this.user?.status == 'ADMIN' || this.user?.status == 'SUPERADMIN';
    }

    public isSuperAdmin (): boolean {
        return this.user?.status == 'SUPERADMIN';
    }

    public changeDateChoice (event: any) {
        var date = event.target.value;
        this.chosenDate = date;
        if (date === 'CUSTOM') {

        } else {
            var currentDate = new Date();
            switch (date) {
                case 'TODAY':
                    this.startDate.value.setDate(currentDate.getDate());
                    this.endDate.value.setDate(currentDate.getDate());
                    break;
                case 'THIS_WEEK':
                    var firstDay = this.getFirstDayOfWeek(currentDate);
                    var lastDay = this.getLastDayOfWeek(currentDate);
                    this.startDate.value.setDate(firstDay.getDate());
                    this.startDate.value.setFullYear(firstDay.getFullYear());
                    this.startDate.value.setMonth(firstDay.getMonth());
                    this.endDate.value.setDate(lastDay.getDate());
                    this.endDate.value.setFullYear(lastDay.getFullYear());
                    this.endDate.value.setMonth(lastDay.getMonth());
                    break;
                case 'THIS_MONTH':
                    var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                    var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                    this.startDate.value.setDate(firstDay.getDate());
                    this.startDate.value.setFullYear(firstDay.getFullYear());
                    this.startDate.value.setMonth(firstDay.getMonth());
                    this.endDate.value.setDate(lastDay.getDate());
                    this.endDate.value.setFullYear(lastDay.getFullYear());
                    this.endDate.value.setMonth(lastDay.getMonth());
                    break;
                case 'LAST_MONTH':
                    var year = currentDate.getMonth() == 0 ? currentDate.getFullYear()-1 : currentDate.getFullYear();
                    var month = currentDate.getMonth() == 0 ? 11 : currentDate.getMonth()-1;
                    var firstDay = new Date(year, month, 1);
                    var lastDay = new Date(year, month+1, 0);
                    this.startDate.value.setFullYear(firstDay.getFullYear());
                    this.startDate.value.setMonth(firstDay.getMonth());
                    this.startDate.value.setDate(firstDay.getDate());
                    this.endDate.value.setFullYear(lastDay.getFullYear());
                    this.endDate.value.setMonth(lastDay.getMonth());
                    this.endDate.value.setDate(lastDay.getDate());
                    break;
                default:
                    this.startDate.value.setDate(currentDate.getDate());
                    this.endDate.value.setDate(currentDate.getDate());
                    break;
                    
            }
            this.loadMachineSales();
        }
        
    }

    private getFirstDayOfWeek(d: Date) {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    private getLastDayOfWeek(d: Date) {
        d = new Date(d);
        var day = d.getDay(),
        diff = d.getDate() - day + 7; // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    public changeDate () {
        this.startDate.value.setDate(this.startDate.value.getDate() +1);
        this.endDate.value.setDate(this.endDate.value.getDate() +1);
        this.loadMachineSales();
    }

    public isStatusOk (machine: Machine) {
        return machine.statusCode === "OK";
    }

    public formatProductName (productName: string) {
        switch (productName) {
            case 'WATER':
                return 'eau';
            case 'CREATINE':
                return 'Créatine';
            case 'PROTEIN_ISOLATE':
                return 'Protéine Isolate';
            case 'PROTEIN_ISOLATE_VANILLA':
                return 'Protéine Isolate vanille';
            case 'PROTEINE_ISOLATE_VANILLE':
                return 'Protéine Isolate vanille';
            case 'PROTEIN_ISOLATE_CHOCOLATE':
                return 'Protéine Isolate chocolat';
            case 'PROTEINE_ISOLATE_CHOCOLAT':
                return 'Protéine Isolate chocolat';
            case 'PROTEIN_ISOLATE_COCO':
                return 'Protéine Isolate coco';
            case 'PROTEINE_ISOLATE_COCO':
                return 'Protéine Isolate coco';
            case 'PROTEIN_ISOLATE_COOKIE':
                return 'Protéine Isolate cookie';
            case 'PROTEINE_ISOLATE_COOKIE':
                return 'Protéine Isolate cookie';
            case 'PROTEIN_ISOLATE_STRAWBERRY':
                return 'Protéine Isolate Fraise';
            case 'PUMP_MIXED_BERRIES':
                return 'PUMP fruits des bois';
            case 'PUMP_FRUIT_PUNCH':
                return 'PUMP fruits punch';
            case 'PUMP_PUNCH_FRUIT':
                return 'PUMP fruits punch';
            case 'PROTEIN':
                return 'Protéine';
            case 'PROTEIN_VANILLA':
                return 'Protéine vanille';
            case 'PROTEIN_CHOCOLATE':
                return 'Protéine chocolat';
            case 'GAINEUR':
                return 'Gaineur';
            case 'GAINEUR_VANILLA':
                return 'Gaineur vanille';
            default:
                return productName;
        }
    }

    public formatProductTaste (productTaste: string) {
        switch (productTaste) {
            case 'VANILLA':
                return 'Vanille';
            case 'CHOCOLATE':
                return 'Chocolat';
            case 'COCO':
                return 'Coco';
            case 'COOKIE':
                return 'Cookie';
            case 'STRAWBERRY':
                return 'Fraise';
            case 'MIXED_BERRIES':
                return 'fruits des bois';
            case 'PUNCH_FRUIT':
                return 'fruits punch';
            default:
                return productTaste;
        }
    }

    public formatLevel (level: string) {
        switch (level) {
            case 'LEVEL0':
                return 'Non abonné';
            case 'LEVEL1':
            case 'LEVEL2':
                return 'Abonné';
            case 'LEVEL3':
                return 'Manager';
            case 'LEVEL4':
                return 'Employé';
            case 'LEVEL5':
                return 'Abonné eau uniquement';
            default:
                return level;
        }
    }

    public formatWater (water: string) {
        switch (water) {
            case '_100CL':
                return '1L';
            default:
                return water.replace('_', '');;
        }
    }

    public formatPrice (price: number) {
        if (!price) {
            return "";
        }
        var priceInEuro = price / 100; 
        return priceInEuro.toFixed(2) + ' €';
    }

    public displayWater () {
        if (this.machine?.waterAvailable) {
            if (this.machine?.waterFree) {
                return 'Oui';
            } else {
                return 'Abonné uniquement';
            }
        } else {
            return 'Non';
        }
    }

    public displayWaterAvailable () {
        switch (this.machine?.waterConfig) {
            case 'WATER1':
                return '30cl, 40cl, 50cl, 70cl';
            case 'WATER2':
                return '30cl, 40cl, 50cl';
            case 'WATER3':
                return '30cl, 40cl, 50cl, 70cl, 1L';
            case 'WATER4':
                return '25cl, 33cl, 50cl';
            case 'WATER5':
                return '50cl';
            default:
                return '50cl, 1L';
        }
    }

    public getNbSachetByDose (productName: string, dose: number) {
        var bagNumber = 0;
        switch (productName) {
            case 'PROTEINE_ISOLATE_VANILLE':
                bagNumber = dose / 25;
                break;
            case 'PROTEINE_ISOLATE_CHOCOLAT':
                bagNumber = dose / 25;
                break;
            case 'PROTEINE_ISOLATE_COOKIE':
                bagNumber = dose / 25;
                break;
            case 'PROTEINE_ISOLATE_COCO':
                bagNumber = dose / 25;
                break;
            case 'PUMP_MIXED_BERRIES':
                bagNumber = dose / 110;
                break;
            case 'PUMP_FRUIT_PUNCH':
                bagNumber = dose / 110;
                break;
            case 'CREATINE':
                bagNumber = dose / 135;
                break;
            case 'BCAA':
                bagNumber = dose / 100;
                break;
            default:
                return 0;
        }
        return bagNumber.toFixed(2);
    }

    public sumLevelDose (sales: LevelSaleTotal[] | WaterSaleTotal[]) {
        if (sales.length > 0) {
            return  sales.map(a => a.totalDose).reduce(function(a, b)
            {
            return a + b;
            });
        }
        return 0;
    }

    public sumProductDose (sales: ProductSaleTotal[]) {
        if (sales.length > 0) {
            const totalDose =  sales.map(a => a.totalDose).reduce(function(a, b)
            {
            return a + b;
            });
            const totalComplement =  sales.map(a => a.totalOption).reduce(function(a, b)
            {
            return a + b;
            });
            const totalBoost =  sales.map(a => a.totalBoost).reduce(function(a, b)
            {
            return a + b;
            });
            return totalDose + totalComplement + (totalBoost / 2);
        }
        return 0;
    }

    public sumPrice (sales: ProductSaleTotal[] | LevelSaleTotal[]) {
        if (sales.length > 0) {
            return sales.map(a => a.totalPrice).reduce(function(a, b)
            {
            return a + b;
            });
        }
        return 0;
    }

    public selectClient (client: any): void {
      this._router.navigate(
        ['admin/client', client.clientId]
      );
    }

    public getLastResetDate (productCode: string): string {
        let filterResetDose = this.resetDoses.filter(resetDose => resetDose.product === productCode);
        if (filterResetDose.length >= 1) {
            return new Date(filterResetDose[0].date).toISOString();
        }
        return '';
    }

    public estimateRemainProductDay (productCode: string, dose: number): string {
        let filterProductStat = this.statsProduct.filter(stat => this.matchProductName(stat.productCode, productCode));
        if (filterProductStat.length >= 1) {
            let productStat = filterProductStat[0];
            return Math.round(dose / productStat.averageDailyDoses) + ' jours';
        }
        return '';
    }

    private matchProductName (productCode: string, productName: string) : boolean {
        switch (productCode) {
            case 'PROTEIN_ISOLATE_VANILLA':
                return productName === 'PROTEIN_ISOLATE_VANILLA' || productName === 'PROTEINE_ISOLATE_VANILLE';
            case 'PROTEIN_ISOLATE_CHOCOLATE':
                return productName === 'PROTEIN_ISOLATE_CHOCOLATE' || productName === 'PROTEINE_ISOLATE_CHOCOLAT';
            case 'PROTEIN_ISOLATE_COCO':
                return productName === 'PROTEIN_ISOLATE_COCO' || productName === 'PROTEINE_ISOLATE_COCO';
            case 'PROTEIN_ISOLATE_COOKIE':
                return productName === 'PROTEIN_ISOLATE_COOKIE' || productName === 'PROTEINE_ISOLATE_COOKIE';
            case 'PUMP_PUNCH_FRUIT':
                return productName === 'PUMP_PUNCH_FRUIT' || productName === 'PUMP_FRUIT_PUNCH';
        }
        return productCode === productName;
    }

    private loadStock () {
        this.stockService.getStock(this.machine.buildingCode).subscribe({
            next: (stockList) => {
                this.stocks = stockList;
            }
        });
    }

    private loadResetDose () {
        this.machineService.getMachineResetDose(this.machine.identifier).subscribe({
            next: (resetDoses) => {
                this.resetDoses = resetDoses;
            }
        });
    }
    
    private loadMachineSales () {
        if (this.id == null) {
            return;
        }
        this.machineService.getMachineSales(this.id, this.startDate.value, this.endDate.value).subscribe({
            next: (sales) => {
                this.sales = sales.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
                const products = [...new Set(sales.map(item => item.taste == '' ? item.product : item.product + '_' + item.taste))];
                if (products.indexOf('CREATINE') === -1 && sales.filter(sale => (sale.complement == 'CREATINE')).length > 0) {
                    products.push('CREATINE');
                }
                this.totalProducts = [];
                this.totalWaters = [];
                products.forEach(product => {
                    if (product !== 'WATER') {
                        this.totalProducts.push({
                            product: product,
                            totalDose: sales.filter(sale => ((sale.taste == '' ? sale.product : sale.product + '_' + sale.taste) == product)).length,
                            totalOption: sales.filter(sale => (sale.complement == product)).length,
                            totalBoost: sales.filter(sale => ((sale.product + '_' + sale.taste) == product && sale.boost)).length,
                            totalPrice: sales.filter(
                                    sale => ((sale.taste == '' ? sale.product : sale.product + '_' + sale.taste) == product)
                                ).reduce((acc, obj) => {
                                        return acc + obj.price;
                                    }, 0
                                )
                        });
                    } else {
                        const waterQuantities = [...new Set(sales.filter(sale => sale.product == 'WATER').map(item => item.water))];
                        waterQuantities.forEach(waterQuantity => {
                            this.totalWaters.push({
                                water: waterQuantity,
                                totalDose: sales.filter(sale => (sale.product == product) && sale.water == waterQuantity).length
                            });
                        });
                    }
                });
                this.totalProducts.sort((a, b) => b.totalDose - a.totalDose);
                const levels = [...new Set(sales.map(item => item.level))];
                this.totalLevels = [];
                levels.forEach(level => {
                    this.totalLevels.push({
                        level: level,
                        totalDose: sales.filter(sale => sale.level == level && sale.product !== 'WATER').length 
                        + sales.filter(sale => sale.level == level && sale.complement === 'CREATINE').length 
                        + (sales.filter(sale => sale.level == level && sale.boost).length / 2),
                        totalCb: sales.filter(sale => sale.level == level && sale.product !== 'WATER' && !sale.clientId && sale.price > 0).length,
                        totalPrice: sales.filter(sale => sale.level == level && sale.product !== 'WATER').reduce((acc, obj) => {
                                return acc + obj.price;
                            }, 0
                        )
                    });
                });
                this.totalLevels.sort((a, b) => b.totalDose - a.totalDose);
            },
            error: (e) => console.error(e)
        });
    }
    
    private loadMachineStat () {
        if (this.id == null) {
            return;
        }
        this.statService.getStatsByMachine(this.id).subscribe({
            next: (stats) => {
                this.statsMachine = stats;
            }
        });
    }
    
    private loadProductStat () {
        if (this.id == null) {
            return;
        }
        this.statService.getProductStatsByMachine(this.id).subscribe({
            next: (stats) => {
                this.statsProduct = stats;
            }
        });
    }
}
