import { Component, Input, OnInit } from '@angular/core';
import { Log } from 'src/app/modules/administration/views/log/models/log.model';
import { MachineService } from '../../../service/machine.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
    _machineId: string = "";
    @Input() set machineId(value: string) {
        this._machineId = value;
        this.loadLogs();
    }

    public logs: Log[] = [];

    displayedColumns: string[] = ['date', 'level', 'message'];
    dataSource: Log[] = [];

    constructor(private machineService: MachineService) {
    }

    ngOnInit() {
        this.loadLogs();
    }

    loadLogs () {
        if (this._machineId !== null && this._machineId !== "") {
            this.machineService.getLogsForMachine(this._machineId).subscribe({
                next: (logs) => {
                    this.logs = logs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
                    this.dataSource = this.logs;
                },
                error: (e) => console.error(e)
            });
        } else {
            this.machineService.getLogs().subscribe({
                next: (logs) => {
                    this.logs = logs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
                    this.dataSource = this.logs;
                },
                error: (e) => console.error(e)
            });
        }
    }

}
