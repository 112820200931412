import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MailService } from '../../../service/mail.service';

@Component({
  selector: 'app-mail-detail',
  templateUrl: './mail-detail.component.html',
  styleUrls: ['./mail-detail.component.scss']
})
export class MailDetailComponent implements OnInit {

    public mail: any;
    
    private id: string|null = null;

    constructor(private mailService: MailService,
        private _Activatedroute:ActivatedRoute
    ) {
    }

    ngOnInit() {
        this._Activatedroute.paramMap.subscribe(paramMap => { 
            this.id = paramMap.get('id');
            if (this.id !== null) {
                this.mailService.getMailById(this.id).subscribe({
                    next: (mail) => {
                        this.mail = mail;
                    },
                    error: (e) => console.error(e)
                });
            }
        });   
    }
}

