<div class="row">
    <div>
        <button
            *ngIf="isSuperAdmin()"
            class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m text-lg"
            color="primary" mat-raised-button
            (click)="addBuilding()">
            {{ 'MODULES.ADMINISTRATION.MACHINE.ADD_GYM' | translate }}
        </button>
        <button
            *ngIf="isSuperAdmin()"
            class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m text-lg"
            color="primary" mat-raised-button
            (click)="addMachine()">
            {{ 'MODULES.ADMINISTRATION.MACHINE.ADD_MACHINE' | translate }}
        </button>
    </div>
    <div class="machine-info">
        <h2 class="title">{{ 'MODULES.ADMINISTRATION.MACHINE.INFORMATION.TITLE' | translate }}</h2>
        <div class="datepicker">
            {{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.DATEPICKER.TITLE' | translate }}
            <select (change)="changeDateChoice($event)" class="date-selection">
                <option *ngFor="let date of dateChoices" [value]="date">
                    {{ 'GENERAL.DATE.'+date | translate }}
                </option>
            </select>
            <mat-form-field *ngIf="chosenDate === 'CUSTOM'">
                <mat-label>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.DATEPICKER.SELECT_DATE' | translate }}</mat-label>
                <mat-date-range-input [rangePicker]="rangePicker">
                    <input matStartDate placeholder="Start date" [formControl]="startDate">
                    <input matEndDate placeholder="End date" [formControl]="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangePicker>
                    <mat-date-range-picker-actions>
                        <button mat-button matDateRangePickerCancel>{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.DATEPICKER.CANCEL' | translate }}</button>
                        <button mat-raised-button color="primary" matDateRangePickerApply (click)="changeDate()">{{ 'MODULES.ADMINISTRATION.MACHINE.DETAIL.DATEPICKER.APPLY' | translate }}</button>
                    </mat-date-range-picker-actions>
                </mat-date-range-picker>
            </mat-form-field>
        </div>
        <table>
            <thead>
                <tr>
                    <th>{{ 'MODULES.ADMINISTRATION.MACHINE.INFORMATION.HEADER.TOTAL_SALES' | translate }}</th>
                    <th>Total eau</th>
                    <th>{{ 'MODULES.ADMINISTRATION.MACHINE.INFORMATION.HEADER.TOTAL_PRICE' | translate }}</th>
                    <th>{{ 'MODULES.ADMINISTRATION.MACHINE.INFORMATION.HEADER.TOTAL_TICKET' | translate }}</th>
                    <th>{{ 'MODULES.ADMINISTRATION.MACHINE.INFORMATION.HEADER.TOTAL_COMMAND' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ sales.length + complements.length }} produit(s)</td>
                    <td>{{ waters.length }} eau(x)</td>
                    <td>{{ getTotalPrice() }}</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="column machine-list">
        <h2 class="title">Machines</h2>
        <div class="machine-grid">
            <div *ngFor="let machine of machines" class="machine" (click)="selectMachine(machine)">
                <img src="/assets/images/machine.png" class="machine__img" alt="machine">
                <p>
                    <span>{{ machine.label }}</span>
                    <span class="dot" [ngClass]="{'dot--ok': isStatusOk(machine), 'dot--nok': !isStatusOk(machine), 'dot--empty': isStockLow(machine), 'dot--warning': isBacEmpty(machine)}"></span>
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>{{ 'MODULES.ADMINISTRATION.MACHINE.INFORMATION.HEADER.TOTAL_SALES' | translate }}</th>
                            <th>Total eau</th>
                            <th>{{ 'MODULES.ADMINISTRATION.MACHINE.INFORMATION.HEADER.TOTAL_PRICE' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ getSaleByMachine(machine.id) + getComplementByMachine(machine.id) + (getBoostByMachine(machine.id) / 2) }}</td>
                            <td>{{ getWaterByMachine(machine.id) }}</td>
                            <td>{{ getTotalPriceByMachine(machine.id) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="isSuperAdmin()">
        <app-logs></app-logs>
    </div>
</div>

