import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackbar: MatSnackBar) { }

  openSnackBar(message: string, action: string, duration: number = 2000, hPosition: MatSnackBarHorizontalPosition = 'end', vPositionType: MatSnackBarVerticalPosition = 'top') {
    this.snackbar.open(message, action, {
      horizontalPosition: hPosition,
      verticalPosition: vPositionType,
      duration: duration
    });
  }
}
