import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProductSale } from '../models/product-sales.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  protected URL: string = environment.backendApi;
  constructor(private httpClient: HttpClient) { }

  public getClients(startDate: Date, machineCodes: []): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    var query = '';
    if (machineCodes) {
      query = '&machineId=' + machineCodes.join();
    }
    return this.httpClient.get<any>(`${this.URL}/clients?startDate=${startDate.toISOString().split('T')[0]}&limit=1000${query}`, httpOptions);
  }

  public getClient(clientId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
      return this.httpClient.get<any>(`${this.URL}/client/${clientId}`, httpOptions);
  }

  public getClientSales(clientId: string, startDate: Date, endDate : Date): Observable<ProductSale[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
      return this.httpClient.get<any>(`${this.URL}/user/${clientId}/sales?startDate=${startDate.toISOString().split('T')[0]}&endDate=${endDate.toISOString().split('T')[0]}&limit=1000`, httpOptions);
  }
}
