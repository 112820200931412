import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MachineListComponent } from './views/machine/machine-list/views/machine-list.component';
import { MachineDetailComponent } from './views/machine/machine-detail/views/machine-detail.component';
import { MachineAddComponent } from './views/machine/machine-add/views/machine-add.component';
import { BuildingAddComponent } from './views/building/building-add/views/building-add.component';
import { LogsComponent } from './views/log/views/logs.component';
import { ListTicketComponent } from './views/ticket/list-ticket/list-ticket.component';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule, Routes } from '@angular/router';
import { ManagerGuard } from 'src/app/services/manager.guard';
import { CommandComponent } from './views/command/command.component';
import { HelpComponent } from './views/help/help.component';
import { StatComponent } from './views/stat/stat.component';
import { DialogContentComponent } from './views/dialog-content/dialog-content.component';
import { AdminGuard } from 'src/app/services/admin.guard';
import { ClientListComponent } from './views/client/client-list/client-list.component';
import { ClientDetailComponent } from './views/client/client-detail/client-detail.component';
import { InvoiceComponent } from './views/invoice/invoice.component';
import { MailComponent } from './views/mail/mail-list/mail.component';
import { MailDetailComponent } from './views/mail/mail-detail/mail-detail.component';
import { AccountComponent } from './views/account/account.component';
import { UserListComponent } from './views/users/user-list/user-list.component';
import { UserDetailComponent } from './views/users/user-detail/user-detail.component';
import { UserAddComponent } from './views/users/user-add/user-add.component';


const appRoutes: Routes = [
  { path: 'admin', redirectTo: 'admin/machines', pathMatch: 'full' },

  {
    path: 'admin', children: [
      {
        path: 'machines',
        canActivate: [ManagerGuard],
        component: MachineListComponent,
        data: {
          title: 'Machine List'
        },
      },
      {
        path: 'machine/new',
        canActivate: [AdminGuard],
        component: MachineAddComponent, data: {
          title: 'Machine Add'
        }
      },
      {
        path: 'machine/:id',
        canActivate: [ManagerGuard],
        component: MachineDetailComponent, data: {
          title: 'Machine Detail'
        }
      },
      {
        path: 'building/new',
        canActivate: [AdminGuard],
        component: BuildingAddComponent, data: {
          title: 'Building Add'
        }
      },
      {
        path: 'stat',
        canActivate: [AdminGuard],
        component: StatComponent, data: {
          title: 'Stat'
        }
      },
      {
        path: 'client',
        canActivate: [ManagerGuard],
        component: ClientListComponent, data: {
          title: 'Client list'
        }
      },
      {
        path: 'client/:id',
        canActivate: [ManagerGuard],
        component: ClientDetailComponent, data: {
          title: 'Client detail'
        }
      },
      {
        path: 'ticket',
        canActivate: [ManagerGuard],
        component: ListTicketComponent, data: {
          title: 'Tickets'
        }
      },
      {
        path: 'invoice',
        canActivate: [AdminGuard],
        component: InvoiceComponent, data: {
          title: 'Factures'
        }
      },
      {
        path: 'command',
        canActivate: [ManagerGuard],
        component: CommandComponent, data: { title: 'Command Management' }
      },
      {
        path: 'mail',
        canActivate: [AdminGuard],
        component: MailComponent, data: { title: 'Mail' }
      },
      {
        path: 'mail/:id',
        canActivate: [AdminGuard],
        component: MailDetailComponent, data: {
          title: 'Mail detail'
        }
      },
      {
        path: 'users',
        canActivate: [AdminGuard],
        component: UserListComponent, data: { title: 'User' }
      },
      {
        path: 'users/new',
        canActivate: [AdminGuard],
        component: UserAddComponent, data: {
          title: 'User Add'
        }
      },
      {
        path: 'users/:id',
        canActivate: [AdminGuard],
        component: UserDetailComponent, data: {
          title: 'User detail'
        }
      },
      {
        path: 'log',
        canActivate: [AdminGuard],
        component: LogsComponent, data: { title: 'Log' }
      },
      {
        path: 'help',
        canActivate: [ManagerGuard],
        component: HelpComponent, data: { title: 'Command Management' }
      },
      {
        path: 'account',
        canActivate: [ManagerGuard],
        component: AccountComponent, data: { title: 'Account' }
      }
    ]
  },

];

@NgModule({
  declarations: [
    MachineListComponent,
    MachineDetailComponent,
    MachineAddComponent,
    BuildingAddComponent,
    LogsComponent,
    ListTicketComponent,
    CommandComponent,
    MailComponent,
    MailDetailComponent,
    HelpComponent,
    DialogContentComponent,
    StatComponent,
    InvoiceComponent,
    ClientListComponent,
    ClientDetailComponent,
    AccountComponent,
    UserListComponent,
    UserDetailComponent,
    UserAddComponent
  ],
  exports: [
    MachineListComponent,
    MachineDetailComponent,
    HelpComponent,
    LogsComponent,
    StatComponent,
    InvoiceComponent,
    ClientListComponent,
    ClientDetailComponent,
    AccountComponent,
    UserListComponent,
    UserDetailComponent,
    UserAddComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    TranslateModule.forChild(),
    RouterModule.forChild(appRoutes),

  ],
})
export class AdministrationModule { }
