import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Log } from 'src/app/modules/administration/views/log/models/log.model';
import { Machine } from 'src/app/modules/administration/models/machine.model';
import { ProductSale } from 'src/app/modules/administration/models/product-sales.model';
import { MachineConfig } from '../models/config.model';
import { MachineModel } from '../models/machine-model.model';
import { ResetDose } from '../models/reset-dose.model';
import { Invoice } from '../models/invoice.model';

@Injectable()
export class MachineService {

  protected URL: string = environment.backendApi;
  public machineData = new BehaviorSubject('');

  constructor(private httpClient: HttpClient) { }

    public getLogs(): Observable<Log[]> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
        return this.httpClient.get<Log[]>(`${this.URL}/machine/logs`, httpOptions);
    }

    public getLogsForMachine(machineId: string): Observable<Log[]> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
        return this.httpClient.get<Log[]>(`${this.URL}/machine/${machineId}/logs`, httpOptions);
    }

    public getMachines(machineCodes: []): Observable<Machine[]> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      var query = '';
      if (machineCodes) {
        query = '?machineCode=' + machineCodes.join();
      }
      return this.httpClient.get<Machine[]>(`${this.URL}/machine${query}`, httpOptions);
    }

    public getMachine(machineId: string): Observable<Machine> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
        return this.httpClient.get<Machine>(`${this.URL}/machine/${machineId}`, httpOptions);
    }

    public getMachineModel(machineId: string): Observable<MachineModel> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
        return this.httpClient.get<MachineModel>(`${this.URL}/machine/${machineId}/model`, httpOptions);
    }

    public getAllMachineSales(startDate: Date, endDate : Date, machineCodes: []): Observable<ProductSale[]> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      var query = '';
      if (machineCodes) {
        query = '&machineCode=' + machineCodes.join();
      }
      return this.httpClient.get<ProductSale[]>(`${this.URL}/machines/sales?startDate=${startDate.toISOString().split('T')[0]}&endDate=${endDate.toISOString().split('T')[0]}&limit=100000${query}`, httpOptions);
    }

    public getMachineSales(machineId: string, startDate: Date, endDate : Date): Observable<ProductSale[]> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
        return this.httpClient.get<ProductSale[]>(`${this.URL}/machine/${machineId}/sales?startDate=${startDate.toISOString().split('T')[0]}&endDate=${endDate.toISOString().split('T')[0]}&limit=100000`, httpOptions);
    }

    public getMachineConfigs(machineId: string): Observable<MachineConfig[]> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
        return this.httpClient.get<MachineConfig[]>(`${this.URL}/machine/${machineId}/configs`, httpOptions);
    }

    public getMachineResetDose(machineId: string): Observable<ResetDose[]> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
        return this.httpClient.get<ResetDose[]>(`${this.URL}/machine/${machineId}/resetDose`, httpOptions);
    }

    public getAllConfigs(): Observable<MachineConfig[]> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
        return this.httpClient.get<MachineConfig[]>(`${this.URL}/machine/configs`, httpOptions);
    }


    public addMachine(machine: any): Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/x-www-form-urlencoded',
          'enctype': 'multipart/form-data'
        })
      };
        return this.httpClient.post<any>(this.URL+'/machine', machine, httpOptions);
    }

    public getInvoiceForMachine(machineId: string): Observable<Invoice[]> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
        return this.httpClient.get<Invoice[]>(`${this.URL}/invoice/${machineId}`, httpOptions);
    }
}
